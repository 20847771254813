<template>
  <v-container fluid class="pa-0 register">
    <back-to-store :title="$t('menu.logIn')"></back-to-store>
    <v-container fluid class="pa-16">
        <v-alert dense v-if="alertVisible" text type="error" class="mt-5">
        {{ $t("alerts.invalidCredentials") }}
      </v-alert>
      <v-form ref="form" @submit.prevent="authenticationUser">
        <p class="text-h5 black-grin text-center text-lg-left">
          {{ $t("profile.email") }}
        </p>
        <v-text-field
          outlined
          v-model="user.email"
          :rules="validationRules.emailRules"
        ></v-text-field>

        <p class="text-h5 black-grin text-center text-lg-left">
          {{ $t("profile.password") }}
        </p>
        <v-text-field
          outlined
          v-model="user.password"
          type="password"
          :rules="validationRules.passwordRules"
        ></v-text-field>

        <v-row class="pt-5 pb-10">
          <v-col
            cols="12"
            lg="6"
            class="justify-center d-flex justify-lg-start"
          >
            <v-btn class="btn_second px-16" type="subtmit">
              {{ $t("text.btnAccess") }}
            </v-btn>
          </v-col>
          <v-col cols="12" lg="6" class="text-lg-right text-center">
            <v-btn
              class="transparent px-0 mt-3"
              elevation="0"
              to="/reset-password"
            >
              <p class="black-grin text-h5 text-lg-right text-center">
                {{ $t("text.forgotYourPassword") }}
              </p>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pt-lg-16">
          <v-col cols="12">
            <p class="black-grin text-h5 text-center">
              {{ $t("text.dontHaveAnAccount") }}
            </p>
          </v-col>
          <v-col cols="12" class="justify-center d-flex">
            <v-btn class="btn_first" type="subtmit" to="/register">
              {{ $t("text.btnCreateAccount") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    
    </v-container>
  </v-container>
</template>
<script>
import backToStore from "@/components/home/back-to-store.vue";
import { mapActions } from "vuex";
import router from "../../router";

export default {
  components: {
    backToStore,
  },
  data() {
    return {
      user: {
        email: null,
        password: null,
      },
      alertVisible: false,
    };
  },
  computed: {
    validationRules() {
      return {
        emailRules: [
          (v) => !!v || "Campo requerido",
          (v) => /.+@.+\..+/.test(v) || "El correo no es válido",
        ],
        passwordRules: [(v) => !!v || "Campo requerido"],
      };
    },
  },
  methods: {
    ...mapActions("customer", ["loginUser"]),
    showAlert() {
      this.alertVisible = true;
      
    },
    async authenticationUser() {
      if (this.$refs.form.validate()) {
        try {
          await this.loginUser(this.user);
          this.$refs.form.reset();
          router.push({ name: "home" });
        } catch (error) {
          if(error.response.status === 401){
           this.showAlert();
          }
        }
      }
    },
  },
};
</script>

 